import axios from "../axiosClient";

export const getProjectById = (projectId) =>
  axios.get(
    `/${projectId}`
    //   {
    //     cancelToken: source.token,
    //   },
  );

export const putProjectById = (projectId, name, siren, actors) => {
  const new_project = {"entity": {"society_name": name, "siren": siren}, "project_actors": actors}
  return axios.put(`/${projectId}`, new_project);
}
  
