import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import jwt from "jwt-decode";

import { axiosAuthClient as axios } from "../../../network/axiosClient";
import domains from "../../../../global";
//import { AppDispatch, RootState } from "services/store/store";

import * as types from "./types";

const handleTokenError = async (dispatch, verify) => {
  const params = new URLSearchParams(window.location.search);
  let refreshToken = params.get("refresh_token");
  if (!refreshToken) {
    refreshToken = window.localStorage.getItem("auth_refresh");
  }
  if (refreshToken) {
    const { data: dataRefresh } = await axios.post("/auth/refresh_token", {
      refresh_token: refreshToken,
    });
    if (dataRefresh.status === "OK") {
      window.sessionStorage.setItem("auth_token", dataRefresh.data.token);
      window.localStorage.setItem(
        "auth_refresh",
        dataRefresh.data.refresh_token
      );
      dispatch(verify());
    } else {
      document.location.assign(
        `${domains.auth}?redirect=${window.location.href}`
      );
    }
  } else {
    document.location.assign(
      `${domains.auth}?redirect=${window.location.href}`
    );
  }
};

function verifyToken() {
  return async (dispatch) => {
    dispatch({ type: types.FETCH_ROLE_REQUEST, payload: undefined });
    try {
      await axios.get("/auth/cert");
      const token = window.sessionStorage.getItem("auth_token");
      if (!token) {
        await handleTokenError(dispatch, verifyToken);
      } else {
        try {
          const decoded = jwt(token);
          if (Date.now() <= decoded.exp * 1000) {
            dispatch({
              type: types.FETCH_ROLE_SUCCEEDED,
              payload: {
                roles: decoded.roles,
                email: decoded.email,
                username: decoded.username,
                iss: decoded.iss,
              },
            });
          } else {
            await handleTokenError(dispatch, verifyToken);
          }
        } catch (err) {
          await handleTokenError(dispatch, verifyToken);
        }
      }
    } catch (err) {
      dispatch({ type: types.FETCH_ROLE_FAILED, payload: err });
    }
  };
}

export function isUserAuthorized(usersRoles, necessaryRoles) {
  return necessaryRoles.every((r) => usersRoles.includes(r));
}

export default verifyToken;
