// import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';

import { connect, Provider } from 'react-redux';
import store from './services/store/stores';

import './index.css';
// import './i18n';

import App from './App';
import reportWebVitals from './reportWebVitals';

// dotenv.config();

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser'); // eslint-disable-line global-require
//   worker.start();
// }


const mapStateToProps = (state) => ({
  currentView: state.funnel.currentView
});

const container = document.getElementById('app');

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
