import React from 'react';

import { useNavigate } from "react-router-dom";
import Button from '../../components/library/Button/Button';
import styles from './Error.module.css';

export default function Error() {
  let navigate = useNavigate();
  return (
    <section className={`fadeIn ${styles.error}`}>
      <h1>404</h1>
      {/* <p className={styles.subtitle}>
        {t('error.title')}
      </p>
      <p className={styles.description}>
        {t('error.description')}
      </p> */}
      <div className={styles.row}>
        <Button
          label="back"
          onButtonClick={() => navigate.go(-2)}
        />
        <Button
          label="return"
          onButtonClick={() => window.location.assign('https://ayomi.fr')}
        />
      </div>
    </section>
  );
}
