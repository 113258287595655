import * as types from "./types";

const initState = {
  status: "IDLE",
  roles: [],
};

export default function rolesReducer(
  state = initState,
  { type, payload } = {}
) {
  switch (type) {
    case types.FETCH_ROLE_REQUEST:
      return { ...state, status: "LOADING" };
    case types.FETCH_ROLE_SUCCEEDED:
      return { ...state, status: "SUCCEEDED", ...payload };
    case types.FETCH_ROLE_FAILED:
      return { ...state, status: "FAILED" };
    default:
      return state;
  }
}
