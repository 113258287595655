//import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getProjectById,
  putProjectById,
} from "../services/network/api/project";
import {
  Typography,
  Container,
  TextField,
  Paper,
  Stack,
  Alert,
  CircularProgress,
} from "@mui/material";
import Button from "../components/library/Button/Button";
import Loading from "../components/library/Loading/Loading";

export default function Project() {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [siren, setSiren] = useState("");
  const [actors, setActors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpadating] = useState(false);
  const [status, setStatus] = useState("");
  const [results, setResults] = useState([]);

  const handleActorsEmailChange = (e, index) => {
    // console.log(index)
    const clonedAcotors = [...actors];
    clonedAcotors[index].user["email"] = e.target.value;
    // console.log(clonedAcotors)
    setActors(clonedAcotors);
  };

  useEffect(() => {
    getProjectById(id).then((response) => {
      setName(response.data.entity.society_name);
      setSiren(response.data.entity.siren);
      setActors(response.data.project_actors);
      setLoading(false);
    });
  }, []);
  return (
    <Container maxWidth="sm">
      {loading ? (
        <Loading />
      ) : (
        <Paper sx={{ m: 1, p: 2 }}>
          <Typography variant="h5" sx={{ p: 2 }}>
            Modify Project {id}
          </Typography>
          <Stack spacing={2} alignItems="flex-start">
            <Typography variant="h6">Society Information</Typography>
            <TextField
              value={name}
              label={"Society Name"}
              variant="standard"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              value={siren}
              label={"Siren"}
              variant="standard"
              fullWidth
              onChange={(e) => setSiren(e.target.value)}
            />
            <Typography variant="h6">Project Actor Information</Typography>
            {actors.map((actor, i) => (
              <TextField
                key={actor.user.iss}
                value={actor.user.email}
                label={
                  actor.user.first_name +
                  " " +
                  actor.user.last_name +
                  "'s email"
                }
                fullWidth
                onChange={(e) => handleActorsEmailChange(e, i)}
              />
            ))}
            {updating ? (
              <CircularProgress />
            ) : (
              <Button
                label="Update"
                onButtonClick={() => {
                  setUpadating(true);
                  putProjectById(id, name, siren, actors).then((response) => {
                    setUpadating(false);
                    setStatus(response.status);
                    setResults(response.data);
                  });
                }}
              />
            )}

            {status === 200 ? (
              results.map((result) => (
                <Alert severity={result.success ? "success" : "warning"}>
                  Modification dans {result.service_name}:{" "}
                  {result.success ? "réussite" : "échec"} {result.message}
                </Alert>
              ))
            ) : status !== "" ? (
              <Alert severity="error">Erreur de serveur</Alert>
            ) : null}
          </Stack>
        </Paper>
      )}
    </Container>
  );
}
