/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Outlet,
  Navigate,
} from "react-router-dom";
import { connect, useDispatch } from "react-redux";

//import type { RootState } from 'services/store/store';

import verifyToken from "../store/features/roles/actions";
import Project from "../../views/Project";
import Loading from "../../components/library/Loading";
import Error from "../../views/Error";

const ROUTES = [
  {
    path: "/",
    roles: [],
    authorizedRole: [],
    routes: [],
    component: Error,
  },
  {
    path: ":id",
    auth: true,
    roles: [],
    authorizedRole: [],
    routes: [],
    component: Project,
  },
];

function ProtectedRoute({ status, roles, authorizedRole }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyToken());
  }, []);

  useEffect(() => {}, [authorizedRole]);

  useEffect(() => {
    if (roles && roles.length > 0 && status === "SUCCEEDED") {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of roles) {
        if (!authorizedRole.includes(role)) {
          if (window.history.length === 1) {
            document.location.assign("https//auth.ayomi.fr/");
          } else {
            navigate(-1);
          }
        }
      }
    }
  }, [status]);

  if (status === "SUCCEEDED") {
    return <Outlet />;
  }

  return <Loading />;
}

const mapStateToProps = (state) => ({
  authorizedRole: state.roles.roles,
  status: state.roles.status,
});

const ConnectedProtectedRoute = connect(mapStateToProps)(ProtectedRoute);

export default function RouteConfig() {
  return (
    <Router>
      <div className="main">
        <div className="content">
          <Routes>
            <Route element={<ConnectedProtectedRoute roles={[]} />}>
              {ROUTES.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
            </Route>
          </Routes>
        </div>
      </div>
    </Router>
  );
}
